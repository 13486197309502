/* Demo 12 Homepage 12 */
/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800");
/* font-family: 'Poppins', sans-serif; */
.footer-bottom .container.no-before:before {
  height: 0 !important; }

.header.header-9 .mobile-menu-toggler {
  font-size: 2.5rem; }

.header.header-9 .logo {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center; }

.header.header-9 .menu > li > a {
  padding-left: 2.6rem;
  padding-top: 1.85rem;
  padding-bottom: 1.85rem; }
  .header.header-9 .menu > li > a::before {
    content: '';
    display: block;
    position: absolute;
    left: 2rem;
    right: -1rem;
    bottom: 1.3rem;
    height: .1rem;
    background-color: #fff;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform .3s ease; }

.header.header-9 .menu > li:hover > a, .header.header-9 .menu > li:focus > a {
  color: #fff; }
  .header.header-9 .menu > li:hover > a::before, .header.header-9 .menu > li:focus > a::before {
    transform-origin: left center;
    transform: scale(1, 1); }

.header.header-9 .header-middle .menu.sf-arrows > li > .sf-with-ul {
  padding-right: 2rem; }

.header.header-9 .main-nav {
  margin-left: 0;
  margin-right: 10rem; }

.header.header-9 .wishlist-link {
  font-size: 2.8rem; }

.header.header-9 .wishlist-link .wishlist-count,
.header.header-9 .cart-dropdown .cart-count {
  min-width: 1.7rem;
  height: 1.7rem;
  margin-bottom: 1.5rem;
  margin-left: -1.2rem; }

.header.header-9 .cart-dropdown:not(:hover):not(.show) .dropdown-toggle, .header.header-9 .compare-dropdown:not(:hover):not(.show) .dropdown-toggle {
  color: #fff; }

.header.header-9 .cart-dropdown:hover, .header.header-9 .cart-dropdown:focus {
  color: var(--primary-color, #cc9966); }
  .header.header-9 .cart-dropdown:hover .cart-txt, .header.header-9 .cart-dropdown:focus .cart-txt {
    color: var(--primary-color, #cc9966); }

.header.header-9 .wishlist-txt,
.header.header-9 .cart-txt {
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0;
  color: #fff;
  margin-left: 1rem;
  margin-top: -.5rem;
  transition: all .3s; }

.header.header-9 .menu li:hover > a::before,
.header.header-9 .menu li.show > a::before,
.header.header-9 .menu li.active > a::before {
  transform-origin: left center;
  transform: scale(1, 1); }

.title {
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: -.025em; }

.heading .title {
  margin-bottom: .7rem; }

.intro-slider-container,
.intro-slide {
  height: 100vh;
  background-color: #4f5356; }

.intro-slide {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center; }
  .intro-slide .intro-content {
    position: static;
    left: auto;
    top: auto;
    transform: translateY(0);
    -ms-transform: translateY(0); }

.intro-subtitle {
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: .1em;
  margin-bottom: 1rem; }

.intro-content {
  padding-top: 2rem; }
  .intro-content .btn {
    border-color: var(--primary-color, #cc9966);
    letter-spacing: .01em; }
    .intro-content .btn i {
      margin-left: .5rem;
      margin-bottom: .1rem; }

.intro-title {
  color: #333333;
  font-weight: 600;
  letter-spacing: -.025em;
  margin-bottom: 1rem;
  text-transform: uppercase; }

.banner-display > a:before {
  background-color: rgba(102, 102, 102, 0.25); }

.banner-display .banner-title {
  font-weight: 600;
  font-size: 1.6rem;
  letter-spacing: -.025em; }

.banner-display .banner-subtitle {
  font-weight: 300;
  font-size: 1.4rem;
  letter-spacing: 0;
  margin-bottom: .5rem;
  margin-top: 0; }

.banner-display .btn.banner-link {
  text-transform: none;
  min-width: 126px; }

.banner-title-hidden .banner-title {
  visibility: hidden;
  transition: all .3s; }

.banner-title-hidden:hover .banner-title, .banner-title-hidden:focus .banner-title {
  visibility: visible; }

.product {
  margin-bottom: 2.6rem;
  background-color: transparent; }
  .product:hover {
    box-shadow: none; }
  .product .product-body {
    padding-left: 0;
    padding-right: 0; }

.product-cat {
  color: #ccc;
  font-weight: 400;
  font-size: 1.3rem;
  text-transform: uppercase; }

.product-title {
  font-size: 1.4rem;
  letter-spacing: 0; }

.product-price {
  font-size: 1.4rem;
  margin-bottom: .5rem; }

.product-nav-dots {
  margin-left: 1px; }

.product.product-4 .product-action-vertical {
  top: 2rem; }

.product.product-4 .product-price {
  flex-direction: column;
  align-items: flex-start; }
  .product.product-4 .product-price .old-price {
    margin-left: 0;
    text-decoration: none;
    margin-top: .3rem; }

.product.product-4 .product-body {
  background-color: transparent; }

.product.product-4 .btn-product {
  color: #333;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  text-transform: uppercase;
  transition: none; }
  .product.product-4 .btn-product span {
    font-size: 1.4rem;
    margin-left: -1.8rem;
    transition: all .3s; }
  .product.product-4 .btn-product:before {
    color: #333;
    opacity: 0;
    transition: all .3s; }
  .product.product-4 .btn-product:hover, .product.product-4 .btn-product:focus {
    background-color: var(--primary-color, #cc9966); }
    .product.product-4 .btn-product:hover::before, .product.product-4 .btn-product:focus::before {
      opacity: 1;
      color: #fff; }
    .product.product-4 .btn-product:hover span, .product.product-4 .btn-product:focus span {
      margin-left: 0; }

.product.product-4 .btn-product:not(:hover):not(:focus) {
  background-color: rgba(255, 255, 255, 0.9); }

.owl-simple .owl-dots {
  margin-top: 1rem; }

.btn-product-icon:not(:hover):not(:focus) {
  color: #333; }

.icon-boxes-container {
  padding-top: .4rem;
  padding-bottom: 2.4rem; }

.icon-box {
  font-size: 1.4rem; }

.icon-box-side {
  margin-left: -1rem; }

.icon-box-icon {
  font-size: 3rem;
  color: var(--primary-color, #cc9966); }

.icon-box-content p {
  letter-spacing: -.01em; }

.icon-box-side .icon-box-icon {
  min-width: 4.2rem;
  text-align: left; }

.icon-box-side .icon-box-title {
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: -.01em; }

.footer-menu a:hover,
.footer-menu a:focus {
  box-shadow: 0 1px 0 var(--primary-color, #cc9966); }

@media screen and (min-width: 768px) {
  .intro-subtitle {
    font-size: 1.4rem;
    margin-bottom: 1.2rem; } }

@media screen and (min-width: 992px) {
  .intro-content .btn {
    min-width: 170px; }
  .intro-content .min-width-sm {
    min-width: 150px; }
  .intro-subtitle {
    margin-bottom: 1.4rem; }
  .intro-title {
    font-size: 4.6rem;
    margin-bottom: 1.7rem; } }

@media screen and (min-width: 1200px) {
  .intro-content .btn {
    min-width: 190px; }
  .intro-title {
    font-size: 6rem; } }

@media screen and (max-width: 767px) {
  .banner-display .banner-title {
    font-size: 1.8rem; } }
